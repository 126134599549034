body {
    display: flex;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: Roboto Mono;
}

p {
    font-size: 1.4vw;
    color: rgba(255,255,255,1);
}

h3 {
    font-weight: normal;
    font-size: 3.6vw;
    font-style: normal;
    text-align: center;
    letter-spacing: -0.2px;
    color: rgba(255,255,255,1);
}

h4 {
    font-weight: normal;
    margin: 0;
    font-size: 2vw;
    font-style: normal;
    text-align: left;
    letter-spacing: -0.6px;
    color: rgba(255,255,255,1);
}

a {
    color: rgba(255,255,255,1);
    font-size: 1.5vw;
    padding-right: 1.2vw;
    text-decoration: none;
    padding-right: 3vw;
    display: flex;
}

.polygon {
    display: flex;
    align-self: flex-end;
    height: 1.5vw;
    fill: rgba(107,33,219,1);
}

.vs_header {
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3.5vw;
    width: 100vw;
    background-color: rgba(0,0,0,1);
    color: rgba(255,255,255,1)
}

.header_logo {
    width: 15vw;
}
.vs_header_left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5vw;
}

.vs_header_right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vs_bg1 {
    top: -40vw;
    height: 141.41vw;
    position: absolute;
    z-index: -10;
    width: 100vw;
}

.vs_bg2 {
    position: absolute;
    z-index: -10;
    width: 100vw;
    top: 101.46vw;
    height: 141.41vw;
    background-color: hsl(0deg 0% 0%);
    transition: none;
}

.vs_half {
    position: absolute;
    z-index: -10;
    top: 88.46vw;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(241,236,236,0) 50%, rgba(0,0,0,1) 100%);
    height: 13vw;
    width: 100vw;
}

.vs_half_blk {
    position: absolute;
    z-index: -10;
    top: 101.46vw;
    background: rgba(0,0,0,1);
    height: 4vw;
    width: 100vw;
}


.vs_background1 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vs_background2 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vs_upper1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vw;
}

.vs_upper1_p {
    width: 50%;
}

.vs_upper2 {
    padding-top: 35vw;
    width: 50%;
}

.vs_about {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.vs_about_img {
    width: 80%;
    margin-top: 1.5vw;
}

.vs_services {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.vs_gridicon {
    max-width: 5vw;
    padding-top: 1vw;
    padding-right: 1vw;
}

.vs_gridcontainer {
    width: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}

.vs_gridobject {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
    padding-right: 3vw;
}

.vs_gridbox {
    width: 90%;
}

.vs_contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.contact_logo {
    padding-top: 5vw;
    max-width: 15vw;
}

@media screen and (max-width: 480px) {
    p {
        font-size: 2.5vw;
    }

    .vs_upper1_p {
        width: 90%;
    }
    
    .vs_upperh3 {
        margin-bottom: 1.5vw;
    }

    .vs_upper2 {
        padding-top: 25vw;
        width: 90%;
    }

    .vs_about {
        width: 90%;
    }

    .vs_bg2 {
        top: 101.46vw;
        min-height: 100%;
        height: 150vh;
        max-height: 130%;
    }

    .vs_gridcontainer {
        grid-template-columns: 1fr 1fr;
    
    }

    .vs_contact {
        width: 100%;
    }

    .vs_header {
        height: 8vw;

    }

    a {
        font-size: 2.5vw;
    }

    .polygon {
        display: flex;
        align-self: center;
        height: 2.5vw;
        fill: rgba(107,33,219,1);
    }

    .header_logo {
        width: 20vw;
    }
}